$(function () {
    setTimeout(function () {location.reload()}, 1000 * 60 * 2);
    // Вешаем sakhcom-меню
    $(sakhcommenu).appendTo('body').sakhnav(
        '.js-sakhcom-menu, #g-footer-logo > img',
        { toTop: true }
    );
    $('.widget-dropdown', document)
        .on('click', '.dropdown-selected', function (e) {
            var $this = $(this),
                $widget = $this.closest('.widget-dropdown');
            if (!$widget.data('suppress-click') || $(e.target).closest('.dropdown-container').length === 0) {
                var isTargetedWidgetExpanded = $widget.hasClass('is-expanded');
                $widget.removeClass('is-expanded');
                if (!isTargetedWidgetExpanded) {
                    $widget.addClass('is-expanded');
                }
            }
        });
    $(document).on('mouseup', function (e) {
        var $widgetsList = $('.widget-dropdown');
        if (!$widgetsList.is(e.target) && $widgetsList.has(e.target).length === 0) {
            $widgetsList.removeClass('is-expanded');
        }
    });

    function hide_when_clicked_outside(el) {
        $(window).on("click.Bst", function (event) {
            if (el.has(event.target).length === 0) {
                el.removeClass('js-list-item-clicked');
                el.removeClass('flight-main__border-shadow');
            }
        });
    }

    $(".flight-list-item").click(function () {
        if ($(this).hasClass('js-list-item-clicked')) {
            hide_when_clicked_outside($(this));
            $(".flight-list-item").removeClass('js-list-item-clicked');
        } else {
            hide_when_clicked_outside($(this));
            $(".flight-list-item").removeClass('js-list-item-clicked');
            $(this).addClass('js-list-item-clicked');
            var href = window.location.href;
            href = href.split('#')[0];
            window.location.href = href + '#' + this.id;
        }
    });
    $('.flight-description').click(function (e) {
        e.stopPropagation();
    });

    $('.flight-city-group').click(function (e) {
        e.stopPropagation();
    });
    // Баннера загружаются отложенно
    setTimeout(function () {
        // Модуль адаптивных баннеров
        SakhBp.init();
    }, 1200);
    $.each($('.js-open-jbox'), function () {
        if (window.isMobile) {
            new jBox('Modal', {
                attach: '#' + this.id,
                closeButton: 'box',
                width: window.screen.width,
                height: window.screen.height,
                addClass: 'ui-popup',
                content: $('#' + $(this).data('content'))
            })
        } else {
            new jBox('Modal', {
                attach: '#' + this.id,
                closeButton: 'box',
                width: 700,
                height: 'auto',
                addClass: 'ui-popup',
                content: $('#' + $(this).data('content'))
            })
        }
    });

    function hideFlights() {
        $('.js-status').each(function () {
            if ($(this).hasClass('status-year')) {
                $(this).find($('.flight-item')).hide();
                $(this).find($('.status__title')).hide();
                $(this).find($('.flight-title-scheduled')).hide();
                if ($(this).parent().find('.status:not(.status-year)').length === 0) {
                    $(this).find($('.status__title')).show();
                    $(this).find($('.flight-title-scheduled')).show();
                    $(this).find($('.flight-item')).first().show().addClass('border-bottom');
                    if ($(this).parent().find($('.status-year')).find($('.flight-item')).length > 1) {
                        $(this).next($('.show-flights__year')).show();
                    }
                } else {
                    $(this).next($('.show-flights__year')).show();
                }
            } else {
                $(this).find($('.flight-item')).hide();
                $(this).find($('.flight-item')).first().show().toggleClass('border-bottom');
                if ($(this).find($('.flight-item')).length >= 2) {
                    $(this).next($('.show-flights__tomorrow')).show();
                }
            }
        });
    }

    hideFlights();

    $('.show-flights__year').click(function () {
        if ($(this).data('mode') === 'show') {
            $(this).parent().find($('.status-year')).show();
            $(this).parent().find($('.status-year')).children().show();
            if ($(this).parent().find('.status:not(.status-year)').length === 0) {
                $(this).parent().find('.status-year').find('.flight-item').first().toggleClass('border-bottom');
            }
            $(this).html($(this).data('hide'));
            $(this).data('mode','hide');
        } else {
            $(this).parent().find('.status-year').children().hide();
            if ($(this).parent().find('.status:not(.status-year)').length === 0) {
                $(this).parent().find('.status-year').find('.status__title').show();
                $(this).parent().find('.status-year').find('.flight-item').first().show().toggleClass('border-bottom');
            }
            $(this).html($(this).data('show'));
            $(this).data('mode','show');
        }
    });
    $('.show-flights__tomorrow').click(function () {
        if ($(this).data('mode') === 'show') {
            $(this).parent().find($('.js-status:not(.status-year)')).find($('.flight-item')).show();
            $(this).parent().find('.status-year').find('.flight-item').first().toggleClass('border-bottom');
            $(this).html($(this).data('hide'));
            $(this).data('mode','hide');
        } else {
            $(this).parent().find($('.js-status:not(.status-year)')).find($('.flight-item')).hide();
            $(this).parent().find($('.js-status:not(.status-year)')).find($('.flight-item')).first().show();
            $(this).parent().find('.status-year').find('.flight-item').first().toggleClass('border-bottom');
            $(this).html($(this).data('show'));
            $(this).data('mode', 'show');
        }
    });

    function fillEmailInput() {
        if (localStorage.getItem('subscribe-email') !== undefined) {
            $('.js-input-subscribe').each(function () {
                $(this).val(localStorage.getItem('subscribe-email'));
            });
            var id = [];
            $.each($('.js-email-subscribe'), function () {
                id.push([this.value, $(this).data('number')]);
            });
            var data = {
                'flight_id': id,
                'email': localStorage.getItem('subscribe-email'),
            };
            if (id.length !== 0) {
                var url = '/subscription/issubscribe'; // адрес скрипта на сервере который сохраняет ID устройства
                var ids = 0;
                var direction = 0;
                $.post(url, {
                    data: data,
                }).done(function (json) {
                    if (json['status']) {
                        $.each(json.data, function () {
                            ids = this['flight_id'];
                            direction = this['flight_direction'];
                            $.each($('.js-email-subscribe'), function () {
                                if (this.value === ids && $(this).data('number').toString() === direction) {
                                    this.innerHTML = window.unsubscribe;
                                    $(this).removeClass('ui-button--save').addClass('ui-button--buy');
                                    $('.flight-title').after($('#flight_item_' + this.value));
                                    $(this).parent().find('.subscribe-form').find('.subscribe-email-enable').show();
                                    $('#flight_item_' + this.value).find('.pushpin').show();
                                }
                            });
                        });
                    }
                });
            }

        }
    }

    fillEmailInput();

    $('.js-email-subscribe').on('click', function () {
        var email = $(this).parent().children('.subscribe-form').children('.js-input-subscribe').val(),
            input = $(this).parent().children('.subscribe-form').children('.js-input-subscribe'),
            error = $(this).parent().children('.subscribe-form').children('.ui-form__help-text');
        if (email === '') {
            input.addClass('ui-control--has-errors');
            error.last().addClass('v-hidden');
            error.first().removeClass('d-none');
            error.first().toggleClass('v-hidden');
            return;
        }
        var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
        if (!reg.test(email)) {
            input.addClass('ui-control--has-errors');
            error.first().addClass('d-none');
            error.last().toggleClass('v-hidden');
            return;
        }
        var data = {
            'flight_id': this.value,
            'email': email,
            'direction': $(this).data('number')
        };
        console.log('Отправка данных на сервер...');
        var url = '/subscription/subscribe'; // адрес скрипта на сервере который сохраняет email
        $.post(url, {
            data: data,
        }).done(function (json) {
            openNotice(json['text']);
        });
        $(this).parent().parent().parent().parent().parent().parent().parent().find('.jBox-closeButton').click();
        localStorage.setItem('subscribe-email', email);
        fillEmailInput();
    });
    $('.js-mobile-header-menu').on('click', function () {
       $(this).next('.dropdown-container').addClass('d-block');
    });
    new jBox('Tooltip', {
        attach: '#search-tab-link',
        position: {
            x: 'center',
            y: 'bottom'
        },
        offset: {
            y: -12,
            x: 0,
        },
        zIndex: 20,
        addClass: 'ui-popover',
        trigger: 'click',
        closeOnClick: 'body',
        content: $('#select-popover-content'),
        animation: 'slide:top'
    });

    $('#js-period-select').change(function () {
        location.href = $('#js-period-select').val();
    });
    $('#js-city-select').change(function () {
        location.href = $('#js-city-select').val();
    });
    $('#js-airport-select').change(function () {
        location.href = $('#js-airport-select').val();
    });
    if (!window.isMobile) {
        new jBox('Tooltip', {
            offset: {
                y: 7,
                x: 7,
            },
            pointer: 'right',
            attach: '.pushpin',
            addClass: 'ui-tooltip',
            animation: 'move',
            content: window.subscribeNotice
        });
    }
    var search = window.location.href.substr(2);
    var flight_id = search.split('#');
    if (flight_id[1] !== undefined) {
        $('#' + flight_id[1]).addClass('js-list-item-clicked');
    }
});