if (!window.isMobile) {
    var tooltip = new jBox('Tooltip', {
        attach: '.js-alias',
        addClass: 'ui-tooltip',
        animation: 'move',
    });

    function setTooltip(id, title) {
        tooltip.destroy();
        $('#' + id).prop('title', title);
        tooltip = new jBox('Tooltip', {
            attach: '.js-alias',
            addClass: 'ui-tooltip',
            animation: 'move',
        });
    }
}