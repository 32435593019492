function get_name_browser(){
    var ua = navigator.userAgent;
    return (ua.search(/Chrome/) > 0 || ua.search(/Firefox/) > 0);
}
function removePushTabs() {
    $.each($('.js-tab-push'), function () {
        $(this).removeClass('ui-nav__link--current');
    });
    $.each($('.js-nav-tab-push'), function () {
        $(this).removeClass('ui-nav__tab--current');
        $(this).find('.subscribe-column').hide();
        if ($(window).width() < '580') {
            $(this).find('.subscribe-push-info--mobile').show();
        } else {
            $(this).find('.subscribe-push-info--desktop').show();
        }
    });
    $.each($('.js-tab-email'),function () {
        $(this).addClass('ui-nav__link--current');
    });
    $.each($('.js-nav-tab-email'),function () {
        $(this).addClass('ui-nav__tab--current');
    });
    $('.js-push-access').show();

    if (navigator.userAgent.search(/Chrome/) > 0) {
        $('.settings').show();
    }
}
if ('serviceWorker' in navigator && get_name_browser()) {
    $('.js-push-access').hide();
    // Регистрация service worker-а, расположенного в корне сайта
    // с указанием более строгого scope
    navigator.serviceWorker.register('/assets/dist/js/firebase-messaging-sw.js').then(function (registration) {
        // браузер поддерживает уведомления
        // вообще, эту проверку должна делать библиотека Firebase, но она этого не делает
        if ('Notification' in window) {
            var messaging = firebase.messaging();
            messaging.useServiceWorker(registration);
            var id = [];
            $.each($('.js-push-subscribe'), function () {
                id.push([this.value, $(this).data('number')]);
            });

            messaging.requestPermission()
                .then(function () {
                    // получаем ID устройства
                    messaging.getToken()
                        .then(function (currentToken) {
                            //console.log(currentToken);
                            if (id.length !== 0) {
                                if (currentToken) {
                                    var data = {
                                        'flight_id': id,
                                        'token': currentToken,
                                    };
                                    var url = '/subscription/issubscribe'; // адрес скрипта на сервере который сохраняет ID устройства
                                    var ids = 0;
                                    var direction = 0;
                                    $.post(url, {
                                        data: data,
                                    }).done(function (json) {
                                        if (json['status']) {
                                            $.each(json.data, function () {
                                                ids = this['flight_id'];
                                                direction = this['flight_direction'];
                                                $.each($('.js-push-subscribe'), function () {
                                                    if (this.value === ids && $(this).data('number').toString() === direction) {
                                                        this.innerHTML = window.unsubscribe;
                                                        $(this).removeClass('ui-button--save').addClass('ui-button--buy');
                                                        $('.flight-title').after($('#flight_item_' + this.value));
                                                        $(this).parent().find('.subscribe-form').find('.js-input-subscribe').hide();
                                                        $(this).parent().find('.subscribe-form').find('.subscribe-push-enable').show();
                                                        $('#flight_item_' + this.value).find('.pushpin').show();
                                                    }
                                                });
                                            });
                                        }
                                    });
                                } else {
                                    console.warn('Не удалось получить токен.');
                                    setTokenSentToServer(false);
                                }
                            }
                        })
                        .catch(function (err) {
                            console.warn('При получении токена произошла ошибка.', err);
                            setTokenSentToServer(false);
                        });
                })
                .catch(function (err) {
                    console.warn('Не удалось получить разрешение на показ уведомлений.', err);
                    removePushTabs();
                });
        }

        function subscribe(button) {
            // запрашиваем разрешение на получение уведомлений
            messaging.requestPermission()
                .then(function () {
                    // получаем ID устройства
                    messaging.getToken()
                        .then(function (currentToken) {
                            console.log(currentToken);

                            if (currentToken) {
                                sendTokenToServer(currentToken, button);
                            } else {
                                console.warn('Не удалось получить токен.');
                                setTokenSentToServer(false);
                            }
                        })
                        .catch(function (err) {
                            console.warn('При получении токена произошла ошибка.', err);
                            setTokenSentToServer(false);
                        });
                })
                .catch(function (err) {
                    console.warn('Не удалось получить разрешение на показ уведомлений.', err);
                    removePushTabs();
                });
        }

        var subscribe_button = $('.flight-description-item .js-push-subscribe');
        subscribe_button.on('click', function () {
            subscribe(this);
        });

// отправка ID на сервер
        function sendTokenToServer(currentToken, button) {
            var data = {
                'flight_id': button.value,
                'token': currentToken,
                'direction': $(button).data('number')
            };
            console.log('Отправка токена на сервер...');
            var url = '/subscription/subscribe'; // адрес скрипта на сервере который сохраняет ID устройства
            $.post(url, {
                data: data,
            }).done(function (json) {
                if (json['status']) {
                    openNotice(json['text']);
                    button.innerHTML = window.unsubscribe;
                    $(button).removeClass('ui-button--save').addClass('ui-button--buy');
                    $('#flight_item_' + button.value).find('.pushpin').show();
                    $('.flight-title').after($('#flight_item_' + button.value));
                    $(button).parent().find('.subscribe-form').find('.js-input-subscribe').show();
                    $(button).parent().find('.subscribe-form').find('.subscribe-push-enable').show();
                    window.scrollTo(0, 0)
                } else {
                    openNotice(json['text']);
                    button.innerHTML = window.subscribe;
                    $(button).removeClass('ui-button--buy').addClass('ui-button--save');
                    $('#flight_item_' + button.value).find('.pushpin').hide();
                    $(button).parent().find('.subscribe-form').find('.js-input-subscribe').show();
                    $(button).parent().find('.subscribe-form').find('.subscribe-push-enable').hide();
                    window.scrollTo(0, 0)
                }
            });
            $(button).parent().parent().parent().parent().parent().parent().parent().find('.jBox-closeButton').click();
            setTokenSentToServer(currentToken);
        }

        // используем localStorage для отметки того,
        // что пользователь уже подписался на уведомления
        function isTokenSentToServer(currentToken) {
            return window.localStorage.getItem('sentFirebaseMessagingToken') == currentToken;
        }

        function setTokenSentToServer(currentToken) {
            if (window.localStorage.getItem('sentFirebaseMessagingToken') !== currentToken) {
                window.localStorage.setItem(
                    'sentFirebaseMessagingToken',
                    currentToken ? currentToken : ''
                );
            }
        }
    }).catch(function (error) {
        console.log('Ошибка при регистрации service worker-а:', error);
        removePushTabs();
    });
} else {
    console.log('Текущий браузер не поддерживает service worker-ы.');
    removePushTabs();
}
// Initialize Firebase
var config = {
    apiKey: "AAAAEajuv-Q:APA91bEVaCXHXdzxWyF_rBBWCGol2mM_1a8JntnFyfqGfeJkJMBviaQeE1yeXj4C7S7PXUEEbiioDqYU2ulfKQHq-Ilio9gaU8fNJx1GKZk5gfWH6W2EbMYCtCJXJMfC8vDLzjkPPl1-",
    authDomain: "airport-2a1ad.firebaseapp.com",
    databaseURL: "https://airport-2a1ad.firebaseio.com",
    projectId: "airport-2a1ad",
    storageBucket: "airport-2a1ad.appspot.com",
    messagingSenderId: "75848663012"
};
firebase.initializeApp(config);