function Tabs(options) {
    var $container = jQuery(options.containerSelector),
        currentLinkClass = options.currentLinkClass || 'ui-nav__link--current',
        currentTabClass = options.currentTabClass || 'ui-nav__tab--current',
        linkSelector = options.linkSelector || '.ui-nav__link';

    $container.find(linkSelector).on('click', function () {
        var $nextLink = jQuery(this),
            $currentContainer = $nextLink.closest(options.containerSelector),
            $currentLink = $container.find('.' + currentLinkClass + ':first'),
            $currentTab = $container.find('.' + currentTabClass + ':first'),
            $nextTab = jQuery($nextLink.attr('href'));

        $currentContainer.trigger('ui-tabs.changing', [{
            $currentLink: $currentLink,
            $nextLink: $nextLink,
            $currentTab: $currentTab,
            $nextTab: $nextTab
        }]);
        $nextLink.parent().find($('.ui-nav__link--current')).removeClass('ui-nav__link--current');
        $nextLink.addClass(currentLinkClass);

        $nextLink.parent().next($('.ui-nav__tabs')).find($('.ui-nav__tab--current')).removeClass('ui-nav__tab--current');
        $nextTab.addClass(currentTabClass);

        $currentContainer.trigger('ui-tabs.changed', [{
            $prevLink: $currentLink,
            $currentLink: $nextLink,
            $prevTab: $currentTab,
            $currentTab: $nextTab
        }]);

        return false;
    });

    this.on = function (eventName, callback) {
        $container.on('ui-tabs.' + eventName, callback);
    };

    this.off = function (eventName) {
        $container.off('ui-tabs.' + eventName);
    };
}

var tabs = new Tabs({
    containerSelector: '.js-tabs'
});