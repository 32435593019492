var positionX = 'center';

if (window.isMobile) {
    positionX = 'center'
} else {
    positionX = 'right'
}
    var notice = new jBox('Notice', {
    content: '',
    addClass: 'ui-notice ui-notice--info',
    trigger: 'click',
    closeOnClick: true,
    position: {
        x: positionX,
        y: 'top'
    },
    offset: {
        y: 10,
        x: -10,
    },
    fixed: true,
});
function openNotice(content) {
    notice.setContent(content).open();
}